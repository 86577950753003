import { Box, Typography } from '@material-ui/core';
import Skeleton from '.';

export default function FormSkeletonPendingBonus() {
  return (
    <>
      {[...new Array(3)].map((item, index) => (
        <Box key={index} my={3} width="100%" display="flex">
          <Box display="flex" justifyContent="flex-start">
            <Skeleton variant="rect" height={64} width={64} />
          </Box>
          <Box ml={4} width="85%">
            <Typography variant="caption">
              <Skeleton height={20} width="90%" />
            </Typography>
            <Typography variant="caption">
              <Skeleton height={20} width="80%" />
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}
