import { Box, Typography } from '@material-ui/core';
import Skeleton from '.';

export default function FormSkeletonDefault() {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box mb={2}>
        <Typography variant="h3">
          <Skeleton width="70%" />
        </Typography>
      </Box>

      <Box mb={6}>
        <Typography variant="body2">
          <Skeleton width="50%" />
        </Typography>
      </Box>
      <Box mt={4} mb={2}>
        <Skeleton variant="rect" height={40} />
      </Box>
      <Box mt={4} mb={2}>
        <Skeleton variant="rect" height={40} />
      </Box>
    </Box>
  );
}
