import { Box, makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import AppAlert from '../AppAlert';

const useStyles = makeStyles((theme) => ({
  restricted: {
    pointerEvents: 'none',
    '& img': {
      filter: 'grayscale(100%)'
    }
  }
}));

const GameRestricted = (props) => {
  const { children, restricted, message, hideTooltip, ...rest } = props;
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const _onClick = (e) => {
    if (restricted) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  if (restricted) {
    return (
      <React.Fragment {...rest}>
        <Tooltip
          title={formatMessage({ id: 'common.restricted' })}
          placement="top"
          arrow
          disableHoverListener={hideTooltip}
          disableTouchListener={hideTooltip}
          disableFocusListener={hideTooltip}
          enterTouchDelay={0}>
          <Box onClick={_onClick}>
            {!!message && (
              <Box mt={8} ml={4} mr={4} display="flex" justifyContent="center">
                <AppAlert severity="info" showClose={false}>
                  {message}
                </AppAlert>
              </Box>
            )}
            <Box className={classes.restricted}>{children}</Box>
          </Box>
        </Tooltip>
      </React.Fragment>
    );
  }
  return <React.Fragment {...rest}>{children}</React.Fragment>;
};

GameRestricted.defaultProps = {
  restricted: false,
  message: '',
  hideTooltip: false
};

GameRestricted.propTypes = {
  restricted: PropTypes.bool,
  message: PropTypes.string,
  hideTooltip: PropTypes.bool
};

export default GameRestricted;
