import { COLORS } from '@/shared/constants/ColorSets';
import { fade, withStyles } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import IconButton from '../Button/IconButton';
import Icon from '../Icon';

const Alert = withStyles((theme) => ({
  root: {
    padding: 6,
    backdropFilter: 'blur(8px)',
    alignItems: 'center'
  },
  outlinedSuccess: {
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.success.main, 0.15),
    border: '2px solid '.concat(fade(theme.palette.success.main, 0.3)),
    '& .MuiAlert-icon': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.success.main
    }
  },
  outlinedInfo: {
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.info.main, 0.15),
    border: '2px solid '.concat(fade(theme.palette.info.main, 0.3)),
    '& .MuiAlert-icon': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.info.main
    }
  },
  outlinedWarning: {
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.warning.main, 0.15),
    border: '2px solid '.concat(fade(theme.palette.warning.main, 0.3)),
    '& .MuiAlert-icon': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.warning.main
    }
  },
  outlinedError: {
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.error.main, 0.15),
    border: '2px solid '.concat(fade(theme.palette.error.main, 0.3)),
    '& h2.MuiAlert-icon': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.error.main
    }
  },
  filledInfo: {
    color: COLORS.gray70,
    border: `1px solid currentColor`,
    backgroundColor: theme.palette.background.card,
    textAlign: 'left',
    '&.MuiAlert-root': {
      padding: theme.spacing(2, 4)
    },
    '& .MuiAlert-message': {
      padding: 0
    },
    '& .MuiTypography-root.MuiTypography-body2': {
      color: 'currentColor',
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.6,
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
        lineHeight: '20px'
      }
    },
    '& .MuiAlert-icon': {
      padding: 0,
      width: 'auto',
      height: 'auto',
      marginRight: theme.spacing(4)
    }
  },
  icon: {
    opacity: 1,
    width: 36,
    height: 36,
    padding: 6,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center'
  },
  action: {
    marginRight: 0
  }
}))(MuiAlert);

/**
 * App alert
 * @param {AlertProps} props
 * @returns {React.ReactElement} Alert
 */
export default function AppAlert({ onClose, showClose, action, ...nest }) {
  return (
    <Alert
      {...nest}
      iconMapping={{
        error: <Icon name="cancel" />,
        info: <Icon name="info" />,
        success: <Icon name="check-circle" />,
        warning: <Icon name="info" />
      }}
      action={
        action ? (
          action
        ) : showClose ? (
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <Icon name="close" fontSize="inherit" />
          </IconButton>
        ) : null
      }
    />
  );
}

AppAlert.defaultProps = {
  variant: 'outlined',
  showClose: true
};
