import { Grid, Typography } from '@material-ui/core';
import Skeleton from '.';

export default function FormSkeletonCreditCard() {
  return (
    <Grid container spacing={6} justify="center">
      <Grid item xs={6}>
        <Skeleton variant="rect" width={60} height={40} style={{ margin: 'auto' }} />
        <Typography variant="body1" gutterBottom>
          <Skeleton width="100%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          <Skeleton width="20%" />
        </Typography>
        <Skeleton variant="rect" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          <Skeleton width="20%" />
        </Typography>
        <Skeleton variant="rect" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          <Skeleton width="20%" />
        </Typography>
        <Skeleton variant="rect" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" height={40} />
      </Grid>
    </Grid>
  );
}
