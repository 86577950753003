import BalanceSkeleton from './BalanceSkeleton';
import FormSkeletonCreditCard from './FormSkeletonCreditCard';
import FormSkeletonDefault from './FormSkeletonDefault';
import FormSkeletonDeposit from './FormSkeletonDeposit';
import FormSkeletonPendingBonus from './FormSkeletonPendingBonus';
import FormSkeletonWithdraw from './FormSkeletonWithdraw';

/**
 * @typedef {Object} FormProps
 * @property {'default'|'deposit'|'deposit-credit-card'|'withdraw'} variant
 */

/**
 * FormSkeleton
 * @param {FormProps} props
 */
export default function FormSkeleton({ variant }) {
  switch (variant) {
    case 'default':
      return <FormSkeletonDefault />;
    case 'deposit':
      return <FormSkeletonDeposit />;
    case 'withdraw':
      return <FormSkeletonWithdraw />;
    case 'deposit-credit-card':
      return <FormSkeletonCreditCard />;
    case 'pending-bonus':
      return <FormSkeletonPendingBonus />;
    case 'balance-wallet':
      return <BalanceSkeleton />;
    default:
      return null;
  }
}
