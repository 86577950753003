import { Grid, Typography } from '@material-ui/core';
import Skeleton from '.';

export default function FormSkeletonDeposit() {
  return (
    <Grid container spacing={6}>
      <Grid item md={6} sm={6} xs={12}>
        <Typography variant="body1" gutterBottom>
          <Skeleton width="40%" />
        </Typography>
        <Skeleton variant="rect" height={40} />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <Typography variant="body1" gutterBottom>
          <Skeleton width="30%" />
        </Typography>
        <Skeleton variant="rect" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          <Skeleton width="20%" />
        </Typography>
        <Skeleton variant="rect" height={40} />
      </Grid>
      <Grid container item xs={12} alignItems="center" justify="center" direction="column">
        <Skeleton variant="rect" width={280} height={280} />
      </Grid>
      <Grid container item xs={12} alignItems="center" justify="center" direction="column">
        <Skeleton variant="rect" width={'50%'} height={24} />
      </Grid>
    </Grid>
  );
}
