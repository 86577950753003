import { Box, makeStyles } from '@material-ui/core';
import Skeleton from '.';

const useStyles = makeStyles((theme) => ({
  item: {
    height: 56,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    padding: theme.spacing(1.5, 4),
    gap: theme.spacing(2)
  },
  circle: {
    gridRowStart: 1,
    gridRowEnd: 3,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  }
}));

export default function BalanceSkeleton() {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {[...new Array(9)].map((item, index) => {
        return (
          <div key={index} className={classes.item}>
            <div className={classes.circle}>
              <Skeleton variant="circle" width={28} height={28} />
            </div>
            <Skeleton variant="rect" width="30%" height={20} />
            <Skeleton variant="rect" width="100%" height={20} />
          </div>
        );
      })}
    </Box>
  );
}
